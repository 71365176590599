/**
 * This is a type guard function to use with Array.filter to filter out items that are undefined. Using only
 * `!== undefined` does not remove type `undefined` from the return type, so a type guard is needed.
 *
 * @example
 * // In this example, the type of the input array is Array<number | undefined>,
 * // and the return type of the filter call is Array<number>:
 * [44, undefined, 3].filter(isDefined) // [44, 3]
 */
export const isDefined = <T>(n: T | undefined): n is T => n !== undefined;

/**
 * This is a type guard function to use with Array.filter to filter out items that are null or undefined.
 */
export const isPresent = <T>(n: T | null | undefined): n is T => n !== null && n !== undefined;
